import { useState } from "react";
import { Modal } from "../Modal";
import "./footer.scss";

export const Footer = () => {
  const [showModal, setShowModal] = useState(false);
  const [contentType, setContentType] = useState("");

  function updateModal(content: string) {
    setContentType(content);
    setShowModal(!showModal);
  }

  return (
    <>
      <footer>
        <div className="logo__container">
          <img src="images/logo_blanc.svg" alt="Brigit santé" width="217" height="77" />
        </div>
        <div className="footer__links">
          <a className="footer__links__link" onClick={() => updateModal("Mentions")} href="#Mentions">
            Mentions légales
          </a>
          <a className="footer__links__link" onClick={() => updateModal("CGU")} href="#CGU">
            CGU
          </a>
          <a className="footer__links__link" onClick={() => updateModal("DonneesPersonnelles")} href="#DonneesPersonnelles">
            Données personnelles
          </a>
          <a className="footer__links__link" onClick={() => updateModal("PolitiqueCookie")} href="#PolitiqueCookie">
            Cookies
          </a>
          <a className="footer__links__link" href="mailto:contact@brigit-sante.fr">
            Contact
          </a>
        </div>
        <div className="hidden-mobile"></div>
      </footer>
      {showModal ? <Modal setShowModal={setShowModal} content={contentType} /> : null}
    </>
  );
};
