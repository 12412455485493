/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
//import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// -----------
// Scss
// -----------
import "./css/main.scss";
// -----------
// Components
// -----------
import { Tags } from "./components/Tags";
import { Footer } from "./components/Footer";
// -----------
// Pages
// -----------
//import Home from "./pages/Home";

function App() {
  const [botComplete, setBotComplete] = useState(false);
  const [isMer, setIsMer] = useState(false);
  useEffect(() => {
    // Hauteur écran
    let deviceHeight: number = window.innerHeight;
    // Hauteur écran pour mobile (prend en compte la barre de recherche et les boutons en bas)
    let vh: number = deviceHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    // Affichage Sticky en mobile lorsque le header disparait de l'écran
    const stickyCta: HTMLButtonElement | null =
      document.querySelector(".sticky-cta");
    if (window.scrollY > deviceHeight) {
      stickyCta!.classList.add("visible");
    }
    // Lors du resize
    window.addEventListener("resize", () => {
      deviceHeight = window.innerHeight;
      vh = deviceHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      if (window.scrollY > deviceHeight) {
        stickyCta!.classList.add("visible");
      }
    });
    // On vérifie si le header est toujours visible (comme le header fait 100% de la hauteur de l'écran, on vérifie avec innerHeight)
    document.addEventListener("scroll", () => {
      if (window.scrollY > deviceHeight) {
        stickyCta!.classList.add("visible");
      } else {
        stickyCta!.classList.remove("visible");
      }
    });
    // Lorsque le bot est fini on affiche les tags correspondants
    window.addEventListener("completebot", () => {
      setBotComplete(true);
    });
    // Lorsque qu'il y a MER
    window.addEventListener("MER", () => {
      setIsMer(true);
    });
  }, []);

  return (
    <>
      <header>
        <div className="header__headline">
          <img className="logo" src="images/logo.svg" width="116" height="42" />
          <img
            className="header__img hidden-mobile"
            src="images/tablette.svg"
            width="450"
            height="291"
          />
          <h1 className="header__title">
            Trouvez la{" "}
            <span>
              mutuelle <br />
              senior
            </span>{" "}
            faite pour vous&nbsp;!
          </h1>
          <div className="header__subtitle-wrapper">
            <ul>
              <li className="header__subtitle">
                <span className="desktop-visible">Mutuelle senior à</span>{" "}
                <span className="desktop-hidden">A</span> partir de 18€/mois​
                <sup>(1)</sup>
              </li>
              <li className="header__subtitle">
                Votre audit 100% gratuit
                <span className="desktop-visible"> et sans engagement</span>​
              </li>
              {/*   <!-- Fake Trustpilot --> */}
              <li className="header__subtitle">
                <div className="fake-trustpilot">
                  <span className="excellent">Excellent</span>
                  <img
                    src="images/stars-4-5.svg"
                    alt="4,5 sur 5 avis trustpilot - mutuelle senior"
                    className="stars"
                  />

                  <div className="trustpilot-label">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.79581 25.6667L8.69165 17.4708L2.33331 11.9583L10.7333 11.2292L14 3.5L17.2666 11.2292L25.6667 11.9583L19.3083 17.4708L21.2041 25.6667L14 21.3208L6.79581 25.6667Z"
                        fill="#00B67A"
                      />
                      <path
                        d="M14 21.3183L19.303 17.4824L19.8333 19.7804L14 21.3183Z"
                        fill="#005128"
                      />
                    </svg>
                    <span>Trustpilot</span>
                  </div>
                </div>
              </li>
              {/*                 <!-- End Fake TrustBox -->*/}
            </ul>
          </div>
        </div>
        <div className="chatbot__wrapper">
          <div className="chatbot__wrapper__subtitle">
            <h3>Faites des économies avec votre conseillère virtuelle</h3>
            <div className="time__wrapper">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM9.78 5H9.72C9.32 5 9 5.32 9 5.72V10.44C9 10.79 9.18 11.12 9.49 11.3L13.64 13.79C13.98 13.99 14.42 13.89 14.62 13.55C14.6702 13.469 14.7036 13.3788 14.7182 13.2846C14.7328 13.1905 14.7283 13.0943 14.705 13.002C14.6817 12.9096 14.64 12.8229 14.5824 12.7469C14.5249 12.671 14.4526 12.6074 14.37 12.56L10.5 10.26V5.72C10.5 5.32 10.18 5 9.78 5Z"
                  fill="#30a16e"
                />
              </svg>
              <span>2 minutes</span>
            </div>
          </div>
          <div className="chatbot__container" id="chatbotContainer"></div>
        </div>
      </header>
      <main>
        <section>
          <div className="section__container">
            <h2 className="section__title">
              Une marque qui tient toutes ses promesses
            </h2>
            <img
              className="section__image hidden-laptop"
              src="images/velo.svg"
              width="267"
              height="251"
            />
            <ol>
              <li>
                <p>
                  <strong>Rapide : </strong>Dénichez l'offre vraiment adaptée à
                  vos besoins en 2 minutes.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pratique : </strong>Exprimez au mieux vos besoins de
                  santé grâce à une interface simple
                </p>
              </li>
              <li>
                <p>
                  <strong>Pertinent : </strong>Assurez votre santé auprès de
                  partenaires parmi les meilleurs du marché
                </p>
              </li>
              <li>
                <p>
                  <strong>Économique : </strong>Profitez de tarifs négociés au
                  mieux pour faire des économies
                </p>
              </li>
            </ol>
            <a
              className="cta-link"
              href={`${process.env.REACT_APP_FORMS_URL}/mutuelle-sante/webbot/page0`}
            >
              <button className="cta hidden-mobile">
                Je trouve ma mutuelle
              </button>
            </a>
          </div>
          <div className="hidden-mobile">
            <img src="images/velo.svg" width="519" height="488" />
          </div>
        </section>
        <section>
          <h2 className="section__title">
            Comment choisir une <span>mutuelle santé sénior</span> performante ?
          </h2>
          <p>
            La mutuelle senior performante est{" "}
            <strong>celle qui couvre les besoins</strong> santé dont vous avez
            réellement besoin. Les attentes d'un senior en termes de
            remboursement santé ne sont évidemment pas les mêmes qu'un jeune
            actif ou d'une famille. C'est la raison pour laquelle comparer les
            mutuelles spécifiquement destinées aux seniors présente de réels
            avantages.
          </p>
          <p>
            <strong>100% indépendant, gratuit et disponible 24h/24</strong>,
            votre coach virtuel <span>Brigit Santé</span> vous propose de
            trouver rapidement la mutuelle senior qui vous correspond,{" "}
            <strong>au meilleur prix</strong>, en répondant simplement à une
            dizaine de questions. Par la suite, libre à vous d'entrer en
            relation avec l'organisme en mesure de répondre à votre besoin de
            remboursement sur les différents postes de dépenses santé.
          </p>
          <div className="section__offers">
            <div className="section__offers__offer">
              <img src="images/audition.svg" width="69" height="69" />
              <h4>Audition</h4>
            </div>
            <div className="section__offers__offer">
              <img src="images/hopital.svg" width="69" height="69" />
              <h4>Hospitalisation</h4>
            </div>
            <div className="section__offers__offer">
              <img src="images/optique.svg" width="69" height="69" />
              <h4>Optique</h4>
            </div>
            <div className="section__offers__offer">
              <img src="images/dentaire.svg" width="69" height="69" />
              <h4>Dentaire</h4>
            </div>
          </div>
        </section>
        <a
          className="sticky-cta"
          href={`${process.env.REACT_APP_FORMS_URL}/mutuelle-sante/webbot/page0`}
        >
          <button className="cta hidden-laptop">Je trouve ma mutuelle</button>
        </a>
      </main>

      <div className="mentions_wrapper">
        <p className="mention_text">
          <sup>(1)</sup> : Tarif valable pour une personne âgée de 55 ans
          bénéficiant du régime Alsace-Moselle dans la gamme des produits santé
          distribués par Meilleurtaux santé (marque exploitée par AFI Assurances
          - courtier immatriculé à l’ORIAS sous le n° 07 027 969 - www.orias.fr)
        </p>
        <p className="mention_text">
          <sup>(2)</sup> : Avis déposés sur le site Trustpilot et représentant
          95% des utilisateurs ayant évalué le service à 4/5 ou 5/5.
        </p>
      </div>

      <Footer />
      <Tags website={0} ciblage={0} insuranceKind={201} />
      {botComplete && <Tags website={0} ciblage={4} insuranceKind={201} />}
      {isMer && <Tags website={0} ciblage={13} insuranceKind={201} />}
    </>
  );
}

export default App;
/* eslint-disable jsx-a11y/alt-text */
