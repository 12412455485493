import { useEffect } from "react";
import { useQuery } from "react-query";
import parse, { Element } from "html-react-parser";
import "./modal.scss";

interface Props {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  content: string;
}

export const Modal: React.FC<Props> = ({ setShowModal, content }) => {
  // Ouvre le détail sélectionné dans le header
  function displayMoreInfo(id: string) {
    let modalBody: HTMLDivElement | null = document.querySelector(".modal__body");
    let allModalDetails = modalBody?.querySelectorAll("details");
    allModalDetails?.forEach((details: HTMLDetailsElement) => {
      details!.open = false;
    });
    let modalDetails: HTMLDetailsElement | null | undefined = modalBody?.querySelector(`#${id}`);
    modalDetails!.open = true;
  }

  // Call API Mentions
  const mentionsQuery = useQuery({
    queryKey: ["mentions"],
    queryFn: async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/Contenu?name=Mentions-BrigitSante`);
      const data = await response.json();
      return data;
    },
    refetchOnWindowFocus: false,
  });

  // Call API CGU
  const CGUQuery = useQuery({
    queryKey: ["CGU"],
    queryFn: async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/Contenu?name=CGU-BrigitSante`);
      const data = await response.json();
      return data;
    },
    refetchOnWindowFocus: false,
  });

  // Call API Données personnelles
  const RGPDQuery = useQuery({
    queryKey: ["RGPD"],
    queryFn: async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/Contenu?name=DonneesPersonnelles-BrigitSante`);
      const data = await response.json();
      return data;
    },
    refetchOnWindowFocus: false,
  });

  // Call API Cookies
  const cookiesQuery = useQuery({
    queryKey: ["cookies"],
    queryFn: async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/Contenu?name=PolitiqueCookie-BrigitSante`);
      const data = await response.json();
      return data;
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!mentionsQuery.isLoading || !CGUQuery.isLoading || !RGPDQuery.isLoading || !cookiesQuery.isLoading) {
      document.querySelectorAll("details:not(.sub__details)").forEach((el: any) => {
        el.addEventListener("click", () => {
          document.querySelectorAll("details:not(.sub__details)").forEach((details) => {
            // @ts-ignore
            if (details !== el) details.open = false;
          });
        });
      });
      displayMoreInfo(content);
    }
  });

  // Affichage si chargement
  if (mentionsQuery.isLoading || CGUQuery.isLoading || RGPDQuery.isLoading || cookiesQuery.isLoading) {
    <div className="modal">
      <div className="modal__container">
        <div className="modal__header">
          <div className="modal__header__links">
            <a className="modal__header__links__link" onClick={() => displayMoreInfo("Mentions")} href="#Mentions">
              Mentions légales
            </a>
            <a className="modal__header__links__link" onClick={() => displayMoreInfo("CGU")} href="#CGU">
              CGU
            </a>
            <a className="modal__header__links__link" onClick={() => displayMoreInfo("DonneesPersonnelles")} href="#DonneesPersonnelles">
              Données personnelles
            </a>
            <a className="modal__header__links__link" onClick={() => displayMoreInfo("PolitiqueCookie")} href="#PolitiqueCookie">
              Cookies
            </a>
          </div>
          <button className="modal__header__button" onClick={() => setShowModal(false)} title="Fermer">
            <span className="close glyphicon glyphicon-remove-circle"></span>
          </button>
        </div>
        <div className="modal__body">
          <p>Chargement en cours...</p>
        </div>
        <div className="modal__footer">
          <button onClick={() => setShowModal(false)}>Fermer</button>
        </div>
      </div>
    </div>;
  }

  // Affichage si erreur
  if (mentionsQuery.error || CGUQuery.error || RGPDQuery.error || cookiesQuery.error) {
    return (
      <div className="modal">
        <div className="modal__container">
          <div className="modal__header">
            <div className="modal__header__links">
              <a className="modal__header__links__link" onClick={() => displayMoreInfo("Mentions")} href="#Mentions">
                Mentions légales
              </a>
              <a className="modal__header__links__link" onClick={() => displayMoreInfo("CGU")} href="#CGU">
                CGU
              </a>
              <a className="modal__header__links__link" onClick={() => displayMoreInfo("DonneesPersonnelles")} href="#DonneesPersonnelles">
                Données personnelles
              </a>
              <a className="modal__header__links__link" onClick={() => displayMoreInfo("PolitiqueCookie")} href="#PolitiqueCookie">
                Cookies
              </a>
            </div>
            <button className="modal__header__button" onClick={() => setShowModal(false)} title="Fermer">
              <span className="close glyphicon glyphicon-remove-circle"></span>
            </button>
          </div>
          <div className="modal__body">
            <p>Il y a eu une erreur, merci de séléctionner une autre option.</p>
          </div>
          <div className="modal__footer">
            <button onClick={() => setShowModal(false)}>Fermer</button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="modal">
        <div className="modal__container">
          <div className="modal__header">
            <div className="modal__header__links">
              <a className="modal__header__links__link" onClick={() => displayMoreInfo("Mentions")} href="#Mentions">
                Mentions légales
              </a>
              <a className="modal__header__links__link" onClick={() => displayMoreInfo("CGU")} href="#CGU">
                CGU
              </a>
              <a className="modal__header__links__link" onClick={() => displayMoreInfo("DonneesPersonnelles")} href="#DonneesPersonnelles">
                Données personnelles
              </a>
              <a className="modal__header__links__link" onClick={() => displayMoreInfo("PolitiqueCookie")} href="#PolitiqueCookie">
                Cookies
              </a>
            </div>
            <button className="modal__header__button" onClick={() => setShowModal(false)} title="Fermer">
              <span className="close glyphicon glyphicon-remove-circle"></span>
            </button>
          </div>
          <div className="modal__body">
            <details id="Mentions">
              <summary>Mentions légales</summary>
              {mentionsQuery.data ? <div className="details__content">{parse(mentionsQuery.data.HtmlContent)}</div> : null}
            </details>
            <details id="CGU">
              <summary>Conditions générales d'utilisation</summary>
              {CGUQuery.data ? (
                <div className="details__content">
                  {parse(CGUQuery.data.HtmlContent, {
                    replace: (domNode) => {
                      if (domNode instanceof Element && domNode.attribs.href === "#ancreMentions") {
                        return (
                          <a onClick={() => displayMoreInfo("Mentions")} href="#Mentions" target="_self">
                            Consulter les mentions légales
                          </a>
                        );
                      } else if (domNode instanceof Element && domNode.attribs.href === "#ancreCGU") {
                        <a onClick={() => displayMoreInfo("CGU")} href="#CGU" target="_self">
                          Consulter les conditions générales d'utilisation
                        </a>;
                      } else if (domNode instanceof Element && domNode.attribs.href === "#ancreDonneesPerso") {
                        <a onClick={() => displayMoreInfo("DonneesPersonnelles")} href="#DonneesPersonnelles" target="_self">
                          Consulter la protection des données personnelles
                        </a>;
                      } else if (domNode instanceof Element && domNode.attribs.href === "#ancrePolitiqueCookie") {
                        <a onClick={() => displayMoreInfo("PolitiqueCookie")} href="#PolitiqueCookie" target="_self">
                          Consulter la politique d'utilisation des cookies
                        </a>;
                      }
                    },
                  })}
                </div>
              ) : null}
            </details>
            <details id="DonneesPersonnelles">
              <summary>Protection des données personnelles</summary>
              {RGPDQuery.data ? <div className="details__content">{parse(RGPDQuery.data.HtmlContent)}</div> : null}
            </details>
            <details id="PolitiqueCookie">
              <summary>Politique d'utilisation des cookies</summary>
              {cookiesQuery.data ? (
                <div className="details__content">
                  {parse(cookiesQuery.data.HtmlContent, {
                    replace: (domNode) => {
                      if (domNode instanceof Element && domNode.attribs.class === "noPrivacy") {
                        return (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a
                            style={{ textDecoration: "underline" }}
                            // eslint-disable-next-line no-script-url
                            href="javascript:void(0)"
                            // @ts-ignore
                            onClick={() => OpenPrivacyCenter()}
                            target="_self"
                            className="noPrivacy"
                          >
                            Renouveler ou modifier votre consentement aux cookies
                          </a>
                        );
                      }
                    },
                  })}
                </div>
              ) : null}
            </details>
          </div>
          <div className="modal__footer">
            <button onClick={() => setShowModal(false)}>Fermer</button>
          </div>
        </div>
      </div>
    </>
  );
};
